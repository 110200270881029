<template>
    <div>
        <DevOnly>
            <LazyUiAppBannerAuthRoles />
        </DevOnly>
        <AppHeader2 />
        <main>
            <div class="px-6 py-32 lg:px-8">
                <UContainer class="max-w-3xl">
                    <slot />
                </UContainer>
            </div>
        </main>
        <LazyAppFooter />
    </div>
</template>
